import { Button, Grid, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import { Header } from '@/components';
import strings from '@/styles/strings';

const PaddedButton = styled(Button)(() => ({
  paddingLeft: '48px',
  paddingRight: '48px',
}));

type Props = {
  onSubmit: () => void;
  onClose: (() => void) | undefined;
  open: boolean;
};

const ChangeEmailDialog = (props: Props) => {
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    props.onSubmit();
  };

  return (
    <Dialog onClose={props.onClose} open={props.open} maxWidth='xs'>
      <Grid container direction='column' alignItems='flex-start' justifyContent='flex-start'>
        <Header title={strings.changeEmailCaption} onClose={props.onClose} />
        <Grid pl='32px' pr='30px' container item direction='column'>
          <Grid item pt='32px'>
            <Typography variant='h4' color='textSecondary'>
              {strings.changeEmailMessage}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-between' pt='54px' pl='28px' pr='40px' pb='27px'>
        <Grid item>
          <Button type='submit' variant='text' size='small' onClick={props.onClose}>
            {strings.cancelCaption}
          </Button>
        </Grid>
        <Grid item>
          <PaddedButton type='submit' variant='contained' onClick={handleSubmit}>
            {strings.continueCaption}
          </PaddedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ChangeEmailDialog;
