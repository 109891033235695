import { FirestoreError, doc, onSnapshot } from 'firebase/firestore';

import type { Dashboard } from '@/data';
import { db } from './firebase';

export const fetchDashboards = (
  storeId: string,
  onDataUpdate: (dashboards: Dashboard[]) => void,
  onError: (error: FirestoreError) => void,
) => {
  const docRef = doc(db, `stores/${storeId}`);

  return onSnapshot(
    docRef,
    (querySnapshot) => {
      const storeConfig = querySnapshot.data();

      if (storeConfig && storeConfig.client_id) {
        onDataUpdate([
          {
            id: 'kpis',
            roles: ['developer', 'customer_success', 'fresh_manager', 'manager', 'store_owner', 'chain_owner'],
            title: 'KPIs',
            url: `${process.env.REACT_APP_DASHBOARD_BASE_URL}/${storeConfig.client_id}/kpis`,
          },
        ]);
      }
    },
    onError,
  );
};
