import { sendPasswordResetEmail, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logIncorrectPasswordEvent, logLoginEvent, logLogoutEvent, logUserNotFoundEvent } from '@/events/user-events';
import { auth } from '@/services';
import { STORE_KEY, getCurrentStore } from '@/store/reducers/store-slice';
import { clearUid, fetchUser, setUid } from '@/store/reducers/user-slice';
import strings from '@/styles/strings';
import { AppException } from '@/utils/app-exception';
import { FirebaseError } from '@firebase/util';

const UID_KEY = 'uid';

const useLogin = () => {
  const dispatch = useDispatch();

  const [pending, setPending] = useState(false);

  const handleAuthStateChanged = useCallback(() => {
    setPending(true);

    return auth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem(UID_KEY, user.uid);
        dispatch(setUid(user.uid));
        fetchUser(user.uid)(dispatch);
        const storeId = localStorage.getItem(STORE_KEY);
        if (storeId) {
          getCurrentStore(storeId)(dispatch);
        }
      }
      setPending(false);
    });
  }, [dispatch]);

  const loginWithEmailAndPassword = useCallback(async ({ email, password }: { email: string; password: string }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      logLoginEvent(email, userCredential.user.uid);
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/wrong-password':
            logIncorrectPasswordEvent(email);
            break;
          case 'auth/user-not-found':
            logUserNotFoundEvent(email);
            break;
          default:
            break;
        }
        throw new AppException(mapAuthCodeToMessage(error.code));
      }
      console.error('Error logging in:', error);
      throw new AppException(strings.genericErrorMessage);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();

      localStorage.removeItem(UID_KEY);
      localStorage.removeItem(STORE_KEY);

      dispatch(clearUid());
      logLogoutEvent();
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw new AppException(mapAuthCodeToMessage(error.code, error.message));
      }
      console.error('Error logging out:', error);
      throw new AppException(strings.genericErrorMessage);
    }
  }, [dispatch]);

  const changeEmail = useCallback(async (email: string) => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      await updateEmail(user, email);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw new AppException(mapAuthCodeToMessage(error.code));
      }
      console.error('Error changing email:', error);
      throw new AppException(strings.genericErrorMessage);
    }
  }, []);

  const changePassword = useCallback(async (password: string) => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      await updatePassword(user, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw new AppException(mapAuthCodeToMessage(error.code));
      }
      console.error('Error changing password:', error);
      throw new AppException(strings.genericErrorMessage);
    }
  }, []);

  const resetPassword = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw new AppException(mapAuthCodeToMessage(error.code));
      }
      console.error('Error resetting password:', error);
      throw new AppException(strings.genericErrorMessage);
    }
  }, []);

  const getIdTokenForCurrentUser = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;
      return await user.getIdToken();
    } catch (error) {
      throw new AppException(strings.genericErrorMessage);
    }
  }, []);

  return {
    pending,
    handleAuthStateChanged,
    loginWithEmailAndPassword,
    logout,
    changeEmail,
    changePassword,
    resetPassword,
    getIdTokenForCurrentUser,
  };
};

const mapAuthCodeToMessage = (code: string, message: string | undefined = undefined) => {
  switch (code) {
    case 'auth/invalid-email':
      return strings.emailInvalidErrorMessage;
    case 'auth/wrong-password':
      return strings.invalidPasswordMessage;
    case 'auth/user-not-found':
      return strings.userNotFoundMessage;
    case 'auth/requires-recent-login':
      return strings.requiresRecentLoginMessage;
    default:
      return message ?? strings.genericErrorMessage;
  }
};

export default useLogin;
