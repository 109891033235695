import { ReactComponent as DashboardIcon } from '@/assets/svg/dashboard.svg';
import { NavButton, ProgressBar } from '@/components';
import type { User } from '@/data';
import { Dashboard } from '@/data';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import { fetchDashboards } from '@/services';
import type { AppState } from '@/store';
import strings from '@/styles/strings';
import { Box, List, ListItem } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  storeId: string;
  onSelect: (dashboard: Dashboard) => void;
  onUpdate: (dashboard: Dashboard | undefined) => void;
  active: boolean;
  expand: boolean;
  showStatusMessage: StatusMessageCallback;
};

const DashboardList = (props: Props) => {
  const { storeId, onSelect, onUpdate, active, expand, showStatusMessage } = props;

  const user = useSelector<AppState, User | undefined>((state) => state.user.user);

  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | undefined>(undefined);

  const userRoles = useMemo<string[] | undefined>(
    () => user?.worksAt?.find((item) => item.store.id === storeId)?.roles,
    [user?.worksAt, storeId],
  );
  const handleUserRoleAuthorization = useCallback(
    (dashboard: Dashboard) => userRoles?.some((role) => dashboard?.roles?.includes(role) ?? false),
    [userRoles],
  );

  const handleSelectDashboard = useCallback(
    (dashboard: Dashboard) => {
      setSelectedDashboard(dashboard);
      onSelect(dashboard);
    },
    [onSelect],
  );

  useEffect(() => {
    setLoading(true);

    return fetchDashboards(
      storeId,
      (dashboards) => {
        const authorizedDashboards = dashboards.filter(handleUserRoleAuthorization);
        setDashboards(authorizedDashboards);

        const dashboard = authorizedDashboards.length > 0 ? authorizedDashboards[0] : undefined;
        if (!dashboard) {
          showStatusMessage(strings.dashboardEmptyAuthorizedMessage, true);
        }

        setSelectedDashboard(dashboard);
        onUpdate(dashboard);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.error('Error fetching dashboards:', error.message);
      },
    );
  }, [storeId, handleUserRoleAuthorization, onUpdate, showStatusMessage]);

  if (loading) {
    return (
      <Box pt='12px' display='flex' justifyContent='center' alignItems='center'>
        <ProgressBar />
      </Box>
    );
  }

  return (
    <List>
      {dashboards.map((dashboard) => (
        <ListItem key={dashboard.id} disableGutters>
          <NavButton
            text={dashboard.title}
            icon={<DashboardIcon />}
            open={active && dashboard.id === selectedDashboard?.id}
            onClick={() => handleSelectDashboard(dashboard)}
            expand={expand}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DashboardList;
