import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

import { ReactComponent as SidebarCollapseIcon } from '@/assets/svg/sidebar-collapse.svg';
import { ReactComponent as SidebarExpandIcon } from '@/assets/svg/sidebar-expand.svg';

export const ToggleHeader = ({ text, onToggle }: { text: string; onToggle: (value: boolean) => void }) => {
  const theme = useTheme();
  const [expand, setExpand] = useState(true);

  const handleToggle = useCallback(() => {
    var value = !expand;
    setExpand(value);
    onToggle(value);
  }, [expand, onToggle]);

  if (!expand) {
    return (
      <IconButton onClick={handleToggle}>
        <SidebarExpandIcon />
      </IconButton>
    );
  }

  return (
    <Grid container direction='row' alignItems='center' justifyContent='space-between'>
      <Grid item xs={6}>
        <Typography pl='16px' variant='h6' color={theme.palette.grey[300]}>
          {text}
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent='flex-end'>
        <IconButton onClick={handleToggle}>
          <SidebarCollapseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
