import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import type { Store, User } from '@/data';
import { fetchUser } from '@/services';

type Props = {
  user: User;
  store: Store;
};

const UserInfo = ({ user: userProp }: Props) => {
  const theme = useTheme();
  const [user, setUser] = useState<User>(userProp);

  useEffect(() => {
    if (!userProp.id) {
      return;
    }

    return fetchUser(
      userProp.id,
      (data) => {
        if (data) {
          setUser(data);
        }
      },
      () => {},
    );
  }, [userProp.id]);

  return (
    <Grid container direction='row'>
      <Typography variant='h4' color={theme.palette.common.white}>
        {user.firstName}
      </Typography>
      <Box pl='8px' />
      <Typography variant='h4' color={theme.palette.common.white}>
        {user.lastName}
      </Typography>
    </Grid>
  );
};

export default UserInfo;
