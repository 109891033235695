import { Grid, Typography, styled } from '@mui/material';

import { ReactComponent as EmailIcon } from '@/assets/svg/mail_outline.svg';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone.svg';
import { ReactComponent as WebsiteIcon } from '@/assets/svg/website.svg';
import type { Contact as ContactModel } from '@/data';

const FRESHFLOW_URL = 'https://freshflow.ai';
const FRESHFLOW_SHORT_NAME = 'freshflow.ai';

const Link = styled(Typography)(() => ({
  textDecoration: 'underline',
}));

const BoxGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.green[5],
  borderRadius: '8px',
}));

const TappableGrid = styled(Grid)(() => ({
  cursor: 'pointer',
}));

type Props = {
  contact: ContactModel;
};

const Contact = (props: Props) => {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleMailClick = (value: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    window.open('mailto:' + value, '_blank');
  };

  return (
    <BoxGrid pl='16px' pr='32px' container direction='column'>
      {props.contact && props.contact?.phone && (
        <Grid pt='38px' spacing={1} container item direction='row'>
          <Grid item>
            <PhoneIcon height='24px' width='24px' />
          </Grid>
          <Grid item>
            <Typography variant='h5' fontWeight='600'>
              {props.contact?.name}
            </Typography>
            <Typography variant='h5' fontWeight='500'>
              {props.contact?.phone}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.contact && props.contact.email && (
        <TappableGrid
          pt='40px'
          spacing={1}
          container
          item
          direction='row'
          alignItems='center'
          onClick={(event) => handleMailClick(props.contact.email, event)}
        >
          <Grid item>
            <EmailIcon height='24px' width='24px' />
          </Grid>
          <Grid item>
            <Link variant='h5' fontWeight='500'>
              {props.contact?.email}
            </Link>
          </Grid>
        </TappableGrid>
      )}
      <TappableGrid
        pt='40px'
        pb='57px'
        spacing={1}
        container
        item
        direction='row'
        alignItems='center'
        onClick={() => openInNewTab(FRESHFLOW_URL)}
      >
        <Grid item>
          <WebsiteIcon height='24px' width='24px' />
        </Grid>
        <Grid item>
          <Typography variant='h5' fontWeight='700'>
            {FRESHFLOW_SHORT_NAME}
          </Typography>
        </Grid>
      </TappableGrid>
    </BoxGrid>
  );
};

export default Contact;
