export default {
  tagLine: 'Fuller\nshelves\nand less\nwaste',
  helloCaption: 'Hello!',
  loginMessage: 'Please enter your credentials to continue',
  forgotPasswordCaption: 'Forgot your password?',
  troubleLoginMessage: 'Having trouble signing in?',
  contactFreshflowCaption: 'Contact Freshflow',
  loginCaption: 'Log in',
  emailEmptyErrorMessage: 'The email field cannot be left empty',
  passwordEmptyErrorMessage: 'The password cannot be empty',
  emailInvalidErrorMessage: 'The email address supplied is not valid',
  emailCaption: 'Email',
  passwordCaption: 'Password',
  resetPasswordCaption: 'Reset Password',
  resetPasswordMessage: `Enter the email associated with your account and we'll send an email with the instructions to reset your password.`,
  resetPasswordSuccessMessage: 'An email has been sent to you with instructions to reset your password',
  sendCaption: 'Send',
  logoutCaption: 'Logout',
  genericErrorMessage: 'Operation was unsuccessful. Please try again',
  userNotFoundMessage: 'The user information requested cannot be found',
  invalidPasswordMessage: 'Please verify you are using the correct password',
  settingCaption: 'Settings',
  userInformationCaption: 'User Information',
  userInformationMessage: 'Please note that any modifications made will also be changed in the mobile application.',
  firstNameCaption: 'First Name',
  lastNameCaption: 'Last Name',
  roleCaption: 'Role',
  changePasswordCaption: 'Change Password',
  confirmCaption: 'Confirm',
  newPasswordCaption: 'Enter New Password',
  confirmPasswordCaption: 'Confirm Password',
  passwordValidationCaption: 'Password must have:',
  passwordValidationMinCharactersMessage: 'A minimum of 8 characters',
  passwordValidationLowerCaseMessage: 'A minimum of 1 lower case letter [a-z]',
  passwordValidationUpperCaseMessage: 'A minimum of 1 upper case letter [A-Z]',
  passwordValidationNumberMessage: 'A minimum of 1 numeric character [0-9]',
  confirmPasswordErrorMessage: 'Passwords must be same',
  changeEmailCaption: 'Change Email',
  changeEmailMessage: 'This change will impact your login process. Are you sure you want to proceed?',
  continueCaption: 'Continue',
  cancelCaption: 'Cancel',
  firstNameUpdateMessage: 'First name updated!',
  lastNameUpdateMessage: 'Last name updated!',
  emailUpdateMessage: 'Email updated!',
  passwordUpdateMessage: 'Password updated!',
  requiresRecentLoginMessage:
    'The current session would need to be refreshed by doing a logout and login again before carrying out this action',
  dashboardErrorMessage: 'An error occurred while loading dashboard. If the issue persists, contact our support team.',
  contactCaption: 'Contact us',
  nameCaption: 'Name',
  messageCaption: 'Message',
  sendMessageCaption: 'Send Message',
  screenshotMessage: 'Send screenshot of the current page to Freshflow',
  nameRequiredErrorMessage: 'Name is required',
  messageRequiredErrorMessage: 'Message is required',
  emailRequiredErrorMessage: 'Email is required',
  contactUsSuccessMessage: 'Your message has been sent',
  dashboardCaption: 'DASHBOARDS',
  dashboardEmptyAuthorizedMessage:
    'Could not find any dashboards you are authorized to access. If you believe this is an error, please contact our support team.',
  dashboardUnAuthorizedMessage:
    'Access to the dashboard is not authorized. If the problem persists, please reach out to our support team for assistance.',
  dashboardNotFoundMessage: 'The dashboard link appears to be broken. If the issue persists, contact our support team.',
} as const;
