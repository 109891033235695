import { Theme } from '@mui/material';
import Button from './Button';
import FormControl from './FormControl';
import Input from './Input';
import MenuItem from './MenuItem';
import Paper from './Paper';
import Select from './Select';
import TextField from './TextField';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Input(theme),
    Paper(),
    Button(theme),
    MenuItem(theme),
    Select(theme),
    TextField(theme),
    FormControl(),
  );
}
