import { ActionFromReducer, Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { User } from '@/data';
import { getUser } from '@/services';
import { STORE_KEY, setCurrentStore } from './store-slice';

const initialState: {
  uid: string | undefined;
  user: User | undefined;
  loading: boolean;
  error: string | undefined;
} = {
  uid: undefined,
  user: undefined,
  loading: false,
  error: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | undefined>) {
      state.loading = false;
      state.user = action.payload;
    },
    setUid(state, action: PayloadAction<string>) {
      state.uid = action.payload;
    },
    clearUid(state) {
      state.uid = undefined;
    },
  },
});

export const { setUid, clearUid } = userSlice.actions;
export const userReducer = userSlice.reducer;

const setUser = userSlice.actions.setUser;

export const fetchUser = (id: string) => (dispatch: Dispatch<ActionFromReducer<typeof setUser>>) => {
  getUser(id).then((user) => {
    dispatch(setUser(user));
    const storeId = localStorage.getItem(STORE_KEY);
    if (!storeId && user && user.worksAt && user.worksAt.length > 0) {
      setCurrentStore(user.worksAt)(dispatch);
    }
  });
};
