export default function FormControl() {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiSelect-icon': {
            color: 'primary',
          },
        },
      },
    },
  };
}
