import { FormControl, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProgressBar } from './ProgressBar';

import { ReactComponent as ArrowIcon } from '@/assets/svg/chevron_down.svg';
import type { Store, User } from '@/data';
import { changeStore } from '@/store';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    fontSize: theme.typography.h4,
    fontWeight: 600,
    color: '#306A57 !important',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
    right: '16px',
  },
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '32px',
  background: 'var(--white-100, #FFF)',
  opacity: 0.8,
  height: '32px',
}));

export const StoreSelector = (props: { user: User; selectedStore: Store }) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState<Store | undefined>();
  const [options, setOptions] = useState<Store[]>([]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const store = options.find((e) => e.id === event.target.value);
      if (store) {
        setSelectedValue(store);
        changeStore(store)(dispatch);
      }
    },
    [dispatch, options],
  );

  useEffect(() => {
    const options = props.user.worksAt?.map((workAt) => workAt.store);
    if (!options) return;
    setOptions(options);
    setSelectedValue(options.find((e) => e.id === props.selectedStore.id));
  }, [props.selectedStore, props.user]);

  if (options.length === 0) return <ProgressBar />;

  return (
    <FormControl fullWidth>
      <StyledSelect
        value={selectedValue?.id ?? options.find((e) => e.id === props.selectedStore.id)?.id}
        onChange={handleChange}
        IconComponent={(props) => <ArrowIcon {...props} />}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};
