import { Dialog, Grid, styled } from '@mui/material';

import type { SendEmailData } from '@/api/contact';
import { Header } from '@/components';
import type { Contact as ContactModel, User } from '@/data';
import strings from '@/styles/strings';
import Contact from './Contact';
import ContactForm from './ContactForm';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '760px',
  },
}));

type Props = {
  onClose: () => void;
  open: boolean;
  contact: ContactModel;
  user: User;
  onSubmit: (data: SendEmailData) => Promise<void>;
};

const ContactDialog = (props: Props) => {
  return (
    <StyledDialog id='dialog' onClose={props.onClose} open={props.open} fullWidth maxWidth='lg'>
      <Grid pb='16px' container direction='column'>
        <Grid item>
          <Header
            variant='h3'
            pl='24px'
            pr='14px'
            title={strings.contactCaption}
            onClose={props.onClose}
            divider={false}
          />
        </Grid>
        <Grid pl='24px' pr='24px' spacing={2} container item direction='row' justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={4}>
            <Contact contact={props.contact} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <ContactForm user={props.user} onSubmit={props.onSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default ContactDialog;
