import { Theme } from '@mui/material';

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        sizeSmall: {
          height: '48px',
        },
        input: {
          fontSize: theme.typography.h4,
          fontWeight: '500',
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '4px',
          fontSize: theme.typography.h4,
          fontWeight: '400',
          color: theme.palette.grey[500],
        },
      },
    },
  };
}
