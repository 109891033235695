import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as WarningIcon } from '@/assets/svg/warning.svg';
import { Dropdown, ProgressBar, TextFormField, TextFormFieldSubmitCallback } from '@/components';
import { ROLES, Store, User as UserModel } from '@/data';
import useLogin from '@/hooks/useLogin';
import { fetchUser, updateUser } from '@/services';
import strings from '@/styles/strings';
import ChangePasswordButton from './ChangePasswordButton';
import EmailField from './EmailField';

import type { StatusMessageCallback } from '@/hoc/withStatus';
import type { AppState } from '@/store';

type Props = {
  showStatusMessage: StatusMessageCallback;
};

const User = ({ showStatusMessage }: Props) => {
  const FIRST_NAME = 'firstName';
  const LAST_NAME = 'lastName';
  const EMAIL = 'email';
  const PASSWORD = 'password';
  const ROLE = 'role';

  const user = useSelector<AppState, UserModel | undefined>((state) => state.user.user);
  const store = useSelector<AppState, Partial<Store> | undefined>((state) => state.store.store);

  const { changeEmail, changePassword } = useLogin();

  const [userData, setUserData] = useState<UserModel | undefined>(undefined);

  const roles = useMemo(() => Object.values(ROLES), []);
  const selectedRole = useMemo(
    () => userData?.worksAt?.filter((data) => data.store.id === store?.id)?.[0]?.roles?.[0],
    [store?.id, userData],
  );

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    return fetchUser(
      user.id,
      (data) => setUserData(data),
      (error) => showStatusMessage(error.message, true),
    );
  }, [user?.id, showStatusMessage]);

  const handleSubmit = useCallback(
    async (data: TextFormFieldSubmitCallback['arguments']) => {
      if (!user?.id) {
        return;
      }

      const { name, value } = data;

      try {
        switch (name) {
          case FIRST_NAME:
            await updateUser(user.id, { firstName: value });
            showStatusMessage(strings.firstNameUpdateMessage);
            break;
          case LAST_NAME:
            await updateUser(user.id, { lastName: value });
            showStatusMessage(strings.lastNameUpdateMessage);
            break;
          case EMAIL:
            await changeEmail(value);
            await updateUser(user.id, { email: value });
            showStatusMessage(strings.emailUpdateMessage);
            break;
          case PASSWORD:
            await changePassword(value);
            showStatusMessage(strings.passwordUpdateMessage);
            break;
          case ROLE:
            // TODO:
            break;
          default:
            break;
        }
      } catch (error) {
        if (error instanceof Error) {
          showStatusMessage(error.message, true);
        } else {
          console.error('Error updating user:', error);
          showStatusMessage(strings.genericErrorMessage, true);
        }
      }
    },
    [changeEmail, changePassword, showStatusMessage, user?.id],
  );

  const handleSubmitButton = useCallback(
    ({ value }: { value: string }) => handleSubmit({ name: { PASSWORD }, value: value }),
    [handleSubmit],
  );

  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography variant='h3' color='textPrimary'>
          {strings.userInformationCaption}
        </Typography>
      </Grid>
      <Grid item>
        <Grid pt='19px' container item direction='row' alignItems='center'>
          <WarningIcon />
          <Box pl='8px' />
          <Typography variant='h5' color='textDisabled'>
            {strings.userInformationMessage}
          </Typography>
        </Grid>
      </Grid>
      {(!userData || !store) && (
        <Grid item pt='16px' alignItems='center' justifyContent='center'>
          <ProgressBar />
        </Grid>
      )}
      {userData && store && (
        <Grid container item direction='column' pt='54px' width='688px'>
          <Grid container item direction='row'>
            <Grid item xs>
              <TextFormField
                title={strings.firstNameCaption}
                value={userData.firstName}
                name={FIRST_NAME}
                onSubmit={handleSubmit}
              />
            </Grid>
            <Box pl='16px' />
            <Grid item xs>
              <TextFormField
                title={strings.lastNameCaption}
                value={userData.lastName}
                name={LAST_NAME}
                onSubmit={handleSubmit}
              />
            </Grid>
          </Grid>
          <Grid container item direction='column'>
            <Grid item pt='16px'>
              <Typography variant='body2' color='textSecondary'>
                {strings.roleCaption}
              </Typography>
            </Grid>
            <Grid item pt='8px'>
              <Dropdown options={roles} value={selectedRole} onChange={handleSubmit} name={ROLE} />
            </Grid>
          </Grid>
          <Grid item pt='16px'>
            <EmailField value={userData.email} name={EMAIL} onSubmit={handleSubmit} />
          </Grid>
          <Grid item pt='16px'>
            <ChangePasswordButton onSubmit={handleSubmitButton} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default User;
