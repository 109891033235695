import type { Store, WorksAt } from '@/data';
import { getStore } from '@/services';
import { ActionFromReducer, Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: {
  storeId: string | undefined;
  store: Store | undefined;
} = {
  storeId: undefined,
  store: undefined,
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<Store>) => {
      state.store = action.payload;
    },
  },
});

export const storeReducer = storeSlice.reducer;

const setStore = storeSlice.actions.setStore;
type SetStoreDispatch = Dispatch<ActionFromReducer<typeof setStore>>;

export const STORE_KEY = 'store_key';
export const setCurrentStore = (worksAt: WorksAt[]) => (dispatch: SetStoreDispatch) => {
  let storeId = localStorage.getItem(STORE_KEY);
  if (!storeId) {
    storeId = worksAt[0].store.id;
    localStorage.setItem(STORE_KEY, storeId);
    dispatch(setStore(worksAt[0].store));
    return;
  }

  getStore(storeId).then((store) => {
    if (store) {
      dispatch(setStore(store));
    }
  });
};

export const changeStore = (store: Store) => (dispatch: SetStoreDispatch) => {
  localStorage.setItem(STORE_KEY, store.id);
  dispatch(setStore(store));
};

export const getCurrentStore = (id: string) => (dispatch: SetStoreDispatch) => {
  getStore(id).then((store) => {
    if (store) {
      dispatch(setStore(store));
    }
  });
};
