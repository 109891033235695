import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import useLogin from '@/hooks/useLogin';
import strings from '@/styles/strings';
import { useCallback } from 'react';
import LoginForm from './LoginForm';

import type { StatusMessageCallback } from '@/hoc/withStatus';
import { AppException } from '@/utils/app-exception';

type Props = {
  showStatusMessage: StatusMessageCallback;
};

const Login = ({ showStatusMessage }: Props) => {
  const { loginWithEmailAndPassword } = useLogin();

  const handleSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      try {
        await loginWithEmailAndPassword(data);
      } catch (error) {
        if (error instanceof AppException) {
          showStatusMessage(error.message, true);
        } else {
          console.error(error);
          showStatusMessage(strings.genericErrorMessage, true);
        }
      }
    },
    [showStatusMessage, loginWithEmailAndPassword],
  );

  return (
    <Grid container direction='column' alignItems='center' justifyContent='center'>
      <Grid item>
        <Typography variant='h2' color='textPrimary'>
          {strings.helloCaption}
        </Typography>
      </Grid>
      <Grid item pt='4px'>
        <Typography variant='h4' color='textPrimary'>
          {strings.loginMessage}
        </Typography>
      </Grid>
      <Grid item pt='59px'>
        <LoginForm onSubmit={handleSubmit} showStatusMessage={showStatusMessage} />
      </Grid>
    </Grid>
  );
};

export default Login;
