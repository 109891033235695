export const ROLES = {
  DEV: { value: 'developer', label: 'Developer' },
  CUSTOMER_SUCCESS: { value: 'customer_success', label: 'Customer Success' },
  FRESH_MANAGER: { value: 'fresh_manager', label: 'Fresh Manager' },
  MANAGER: { value: 'manager', label: 'Manager' },
  STORE_OWNER: { value: 'store_owner', label: 'Store Owner' },
  CHAIN_OWNER: { value: 'chain_owner', label: 'Chain Owner' },
} as const;

export const getRoleFromValue = (value: string) => {
  const foundRole = Object.entries(ROLES).find(([_, roleData]) => roleData.value === value);
  return foundRole ? foundRole[0] : null;
};
