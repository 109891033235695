import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';

import { ReactComponent as ForwardIcon } from '@/assets/svg/chevron_right.svg';
import { Header, PasswordField } from '@/components';
import strings from '@/styles/strings';
import { passwordErrorMap, validatePassword } from '@/utils/validators';

const PaddedButton = styled(Button)(() => ({
  paddingLeft: '28px',
  paddingRight: '28px',
}));

type Props = {
  onSubmit: (value: string) => void;
  onClose: (() => void) | undefined;
  open: boolean;
};

const ChangePasswordDialog = (props: Props) => {
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState<{
    password?: { [key: string]: string } | undefined;
    confirmPassword?: string | undefined;
  }>({});
  const [formData, setFormData] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });

  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await props.onSubmit(formData.password);
    } catch (error) {
      console.error('Error submitting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let errors = { ...formErrors };
    if (name === 'password') {
      errors.password = validatePassword(value);
      errors.confirmPassword =
        formData.confirmPassword && formData.confirmPassword !== value
          ? strings.confirmPasswordErrorMessage
          : undefined;
    }

    if (name === 'confirmPassword') {
      errors.confirmPassword = formData.password !== value ? strings.confirmPasswordErrorMessage : undefined;
    }

    setFormErrors(errors);

    const isFormValid = errors.password && Object.keys(errors.password).length === 0 && !errors.confirmPassword;
    setPending(!isFormValid);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Grid container direction='column' alignItems='flex-start' justifyContent='flex-start'>
        <Header title={strings.changePasswordCaption} onClose={props.onClose} />
        <Grid container item direction='column' pl='40px' pr='40px' pb='30px'>
          <Grid item pt='20px'>
            <Typography variant='h4' color={theme.palette.grey[900]}>
              {strings.passwordValidationCaption}
            </Typography>
          </Grid>
          <Box pt='2px' />
          {Object.values(passwordErrorMap).map((value) => (
            <Grid key={value} container item>
              <Grid item pl='8px'>
                <Typography>&bull;</Typography>
              </Grid>
              <Grid item pl='8px'>
                <Typography
                  pt='2px'
                  variant='h4'
                  color={
                    formErrors.password && Object.values(formErrors.password).includes(value)
                      ? theme.palette.error.main
                      : theme.palette.grey[900]
                  }
                >
                  {value}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Box pt='35px' />
          <Grid item>
            <Typography variant='h5' fontWeight='500' color={theme.palette.grey[900]}>
              {strings.newPasswordCaption}
            </Typography>
          </Grid>

          <Grid item pt='8px'>
            <PasswordField
              name='password'
              value={formData.password}
              error={(formErrors.password && Object.keys(formErrors.password).length !== 0) || false}
              onChange={handleChange}
              style={{ width: '480px' }}
            />
          </Grid>

          <Grid item pt='12px'>
            <Typography variant='h5' fontWeight='500' color={theme.palette.grey[900]}>
              {strings.confirmPasswordCaption}
            </Typography>
          </Grid>

          <Grid item pt='8px'>
            <PasswordField
              name='confirmPassword'
              value={formData.confirmPassword}
              error={Boolean(formErrors.confirmPassword)}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <DialogActions>
        <Box pr='40px' pb='16px'>
          <PaddedButton
            type='submit'
            variant='contained'
            disabled={pending || isLoading}
            style={{
              color: 'white',
              backgroundColor: 'primary',
              opacity: pending || isLoading ? 0.3 : undefined,
            }}
            onClick={handleSubmit}
            endIcon={<ForwardIcon />}
          >
            {isLoading ? <CircularProgress size={24} color='primary' /> : strings.confirmCaption}
          </PaddedButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
