import { Box } from '@mui/material';

import User from '@/features/User/User';
import type { StatusMessageCallback } from '@/hoc/withStatus';

type Props = {
  showStatusMessage: StatusMessageCallback;
};

const Settings = (props: Props) => {
  return (
    <Box>
      <User showStatusMessage={props.showStatusMessage} />
    </Box>
  );
};

export default Settings;
