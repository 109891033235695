import type { Store } from '@/data';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export type SendEmailData = {
  name: string;
  email: string;
  message: string;
  screenshot: boolean;
  attachment?: File;
};

export const sendEmail = async (store: Store, data: SendEmailData): Promise<boolean | undefined> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('store', store.name);
  formData.append('fromEmail', data.email);
  formData.append('toEmail', store.personOfContact.email);
  formData.append('message', data.message);

  if (data.attachment) {
    formData.append('attachment', data.attachment);
  }

  try {
    const response = await fetch(`${API_BASE_URL}/contact`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      return true;
    }
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};
