import { Theme } from '@mui/material';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          textTransform: 'none',
          height: '40px',
        },
        sizeLarge: {
          height: 56,
        },
        containedInherit: {
          color: theme.palette.common.white,
        },
        textInherit: {
          color: theme.palette.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
