import { Box, Grid, IconButton, TextField, Typography, styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useState } from 'react';

import { ReactComponent as CheckIcon } from '@/assets/svg/check.svg';
import React from 'react';

const FormField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.accent.orange,
    },
  },
}));

export type TextFormFieldSubmitCallback = (data: { name: string; value: string | undefined }) => void;

type Props = {
  value: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: TextFormFieldSubmitCallback;
  name: string;
  title: string;
  error?: boolean;
  helperText?: string;
  rows?: number;
  multiline?: boolean;
  endAdornment?: boolean;
};

export const TextFormField = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(props.value);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleSubmit = () => {
    props.onSubmit?.({ name: props.name, value: value });
  };

  return (
    <Grid>
      <Typography variant='body2' color='textSecondary'>
        {props.title}
      </Typography>
      <Box pt='8px'></Box>
      <FormField
        name={props.name}
        variant='outlined'
        value={value}
        error={props.error}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={props.helperText}
        fullWidth
        size={!props.rows ? 'small' : undefined}
        multiline={props.multiline}
        rows={props.rows}
        InputProps={{
          endAdornment: (props.endAdornment ?? true) && (
            <InputAdornment position='end'>
              <IconButton
                disabled={!isFocused}
                aria-label='Apply'
                onMouseDown={handleSubmit}
                edge='end'
                color='primary'
              >
                {isFocused && <CheckIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
