import { Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useCallback, useState } from 'react';

import { PasswordField, ProgressBar } from '@/components';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import strings from '@/styles/strings';
import { validateLoginForm } from '@/utils/validators';
import ForgotPasswordButton from './ForgotPasswordButton';

type Props = {
  onSubmit: (data: { email: string; password: string }) => Promise<void>;
  showStatusMessage: StatusMessageCallback;
};

const LoginForm = ({ onSubmit, showStatusMessage }: Props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState<{
    email?: string;
    password?: string;
  }>({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!loading) {
        const errors = validateLoginForm(formData);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
          setLoading(true);
          await onSubmit(formData);
          setLoading(false);
        }
      }
    },
    [formData, onSubmit, loading],
  );

  return (
    <Box width='400px'>
      <form onSubmit={handleSubmit}>
        <Grid container direction='column' justifyContent='center'>
          <TextField
            label={strings.emailCaption}
            name='email'
            variant='outlined'
            size='small'
            margin='dense'
            value={formData.email}
            error={Boolean(formErrors.email)}
            onChange={handleChange}
            helperText={formErrors.email}
            fullWidth
          />
          <Box pt='27px'>
            <PasswordField
              name='password'
              label={strings.passwordCaption}
              value={formData.password}
              error={Boolean(formErrors.password)}
              onChange={handleChange}
              errorText={formErrors.password}
            />
          </Box>
          <Grid container item direction='column' alignItems='flex-end'>
            <ForgotPasswordButton email={formData.email} showStatusMessage={showStatusMessage} />
          </Grid>
          <Grid pt='32px' item>
            <Button type='submit' size='large' variant='contained' fullWidth>
              {loading && <ProgressBar color='white' />}
              {loading ? '' : strings.loginCaption}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LoginForm;
