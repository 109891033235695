import strings from '@/styles/strings';
import { AppException } from '@/utils/app-exception';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const loadDashboard = async (url: string, firebaseIdToken: string | undefined) => {
  const response = await fetch(`${API_BASE_URL}/dashboard?url=${encodeURI(url)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${firebaseIdToken}`,
    },
  });

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new AppException(strings.dashboardUnAuthorizedMessage);
      case 404:
        throw new AppException(strings.dashboardNotFoundMessage);
      default:
        throw new AppException(strings.dashboardErrorMessage);
    }
  }

  return response.text();
};
