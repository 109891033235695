import { Button, Checkbox, FormControlLabel, Grid, styled, useTheme } from '@mui/material';
import { useState } from 'react';

import { SendEmailData } from '@/api/contact';
import { ReactComponent as ForwardIcon } from '@/assets/svg/chevron_right.svg';
import { ProgressBar, TextFormField } from '@/components';
import { User } from '@/data';
import strings from '@/styles/strings';
import { isValidEmail } from '@/utils/validators';

const PaddedButton = styled(Button)(() => ({
  paddingLeft: '18px',
  paddingRight: '18px',
}));

type Props = {
  user: User;
  onSubmit: (data: SendEmailData) => Promise<void>;
};

const ContactForm = (props: Props) => {
  const theme = useTheme();

  const [formErrors, setFormErrors] = useState<{
    message?: string;
    name?: string;
    email?: string;
  }>({});
  const [formData, setFormData] = useState<SendEmailData>({
    name: props.user.firstName + ' ' + props.user.lastName,
    email: props.user.email,
    message: '',
    screenshot: true,
  });
  const [checked, setChecked] = useState(true);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const errors: {
      message?: string;
      name?: string;
      email?: string;
    } = {};

    if (!formData.message.trim()) {
      errors.message = strings.messageRequiredErrorMessage;
    }

    if (!formData.name.trim()) {
      errors.name = strings.nameRequiredErrorMessage;
    }

    if (!formData.email.trim()) {
      errors.email = strings.emailRequiredErrorMessage;
    } else if (!isValidEmail(formData.email)) {
      errors.email = strings.emailInvalidErrorMessage;
    }

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      formData.screenshot = checked;
      await props.onSubmit(formData);
      setIsLoading(false);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Grid container pt='8px' direction='column'>
      <Grid container item direction='row'>
        <Grid item xs={6}>
          <TextFormField
            endAdornment={false}
            title={strings.nameCaption}
            value={formData.name}
            name='name'
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item pl='16px' xs={6}>
          <TextFormField
            endAdornment={false}
            title={strings.emailCaption}
            value={formData.email}
            name='email'
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid item pt='8px'>
        <TextFormField
          endAdornment={false}
          title={strings.messageCaption}
          value={formData.message}
          name='message'
          multiline={true}
          rows={3}
          error={Boolean(formErrors.message)}
          helperText={formErrors.message}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          name='screenshot'
          control={<Checkbox checked={checked} onChange={handleChecked} inputProps={{ 'aria-label': 'controlled' }} />}
          label={strings.screenshotMessage}
        />
      </Grid>
      <Grid container item pt='38px' justifyContent='flex-end'>
        <PaddedButton type='submit' variant='contained' onClick={handleSubmit} endIcon={<ForwardIcon />}>
          {isLoading ? <ProgressBar color={theme.palette.common.white} /> : strings.sendMessageCaption}
        </PaddedButton>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
