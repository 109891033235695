import { Box, Button, DialogActions, Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import { ReactComponent as ForwardIcon } from '@/assets/svg/chevron_right.svg';
import { Header } from '@/components';
import strings from '@/styles/strings';
import { validateResetPasswordForm } from '@/utils/validators';
import { useCallback, useState } from 'react';

type Props = {
  email: string;
  onReset: (email: string) => void;
  onClose: () => void;
  open: boolean;
};

const ResetPasswordDialog = ({ email: inputEmail, onReset, onClose, open }: Props) => {
  const [formErrors, setFormErrors] = useState<{
    email?: string;
  }>({});
  const [email, setEmail] = useState(inputEmail);

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      const errors = validateResetPasswordForm({ email: email });
      setFormErrors(errors);

      if (Object.keys(errors).length === 0) {
        onReset(email);
      }
    },
    [email, onReset],
  );

  return (
    <Dialog onClose={onClose} open={open}>
      <Grid container direction='column' alignItems='flex-start' justifyContent='flex-start'>
        <Header title={strings.resetPasswordCaption} onClose={onClose} />
        <Grid pl='40px' pr='40px' container item direction='column'>
          <Box pt='32px' />
          <Typography>{strings.resetPasswordMessage}</Typography>
          <Box pt='50px' />
          <Typography>{strings.emailCaption}</Typography>
          <TextField
            name='email'
            variant='outlined'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
        </Grid>
        <Grid item pt='30px'>
          <Box justifyContent='flex-end'></Box>
        </Grid>
      </Grid>
      <DialogActions>
        <Box pr='40px' pb='16px'>
          <Button type='submit' variant='contained' size='small' onClick={handleSubmit} endIcon={<ForwardIcon />}>
            {strings.sendCaption}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
