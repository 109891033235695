export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = 'Inter';
const FONT_SECONDARY = 'EuclidCircular';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 800,
    fontSize: pxToRem(90),
  },
  h2: {
    fontWeight: 600,
    fontSize: pxToRem(38),
  },
  h3: {
    fontWeight: 600,
    fontSize: pxToRem(18),
  },
  h4: {
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  h5: {
    fontWeight: 400,
    fontSize: pxToRem(14),
  },
  h6: {
    fontWeight: 500,
    fontSize: pxToRem(12),
  },
  label: {
    fontWeight: 500,
    fontSize: pxToRem(14),
  },
  button: {
    fontSize: pxToRem(16),
    fontWeight: '600',
  },
};

export default typography;
