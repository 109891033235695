import { Theme } from '@mui/material';

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': theme.palette.grey[200],
          '--TextField-brandBorderHoverColor': theme.palette.grey[400],
          '--TextField-brandBorderFocusedColor': '#6F7E8C',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
  };
}
