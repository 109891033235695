import { Box, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar, TopBar } from '@/components';
import Dashboard from '@/features/Dashboard/Dashboard';
import Settings from '@/features/Settings/Settings';
import SideBar from './SideBar';

import type { Dashboard as DashboardModel, Store, User } from '@/data';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import type { AppState } from '@/store';

type Props = {
  showStatusMessage: StatusMessageCallback;
};

const Home = (props: Props) => {
  const theme = useTheme();

  const user = useSelector<AppState, User | undefined>((state) => state.user.user);
  const store = useSelector<AppState, Store | undefined>((state) => state.store.store);

  const [showSettings, setShowSettings] = useState(false);
  const [dashboard, setDashboard] = useState<DashboardModel | undefined>();

  const handleSettingsClick = useCallback(() => {
    setShowSettings(!showSettings);
  }, [showSettings]);

  const handleSelectDashboard = useCallback(
    (value: DashboardModel) => {
      if (dashboard?.id !== value.id) {
        setDashboard(value);
      }
      if (showSettings) {
        setShowSettings(false);
      }
    },
    [dashboard?.id, showSettings],
  );

  if (!user || !store) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <ProgressBar />
      </Box>
    );
  }

  return (
    <Grid container direction='column' bgcolor={theme.palette.common.white}>
      <Grid item>
        <TopBar user={user} store={store} />
      </Grid>
      <Grid container item direction='row'>
        <Grid item>
          <SideBar
            user={user}
            store={store}
            showSettings={showSettings}
            openSettingsMenu={handleSettingsClick}
            showDashboard={handleSelectDashboard}
            updateDashboard={setDashboard}
            showStatusMessage={props.showStatusMessage}
          />
        </Grid>

        <Grid item xs>
          <Stack>
            {showSettings && (
              <Box pl='88px' pt='40px' height='calc(100vh - 48px)'>
                <Settings showStatusMessage={props.showStatusMessage} />
              </Box>
            )}
            {dashboard && <Dashboard store={store} data={dashboard} />}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
