import { DocumentReference, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from './firebase';

import type { User, WorksAt } from '@/data';
import { AppException } from '@/utils/app-exception';
import { mapToStore } from './store-service';

type WorksAtDocument = {
  store: DocumentReference;
  roles: string[];
};

const fetchWorksAtData = async (worksAtList: WorksAtDocument[]): Promise<WorksAt[]> => {
  const worksAtPromises: Promise<WorksAt | undefined>[] = worksAtList.map(async (worksAt: WorksAtDocument) => {
    try {
      const docSnap = await getDoc(worksAt.store);
      const data = docSnap.data();
      if (!data) {
        console.warn('Retrieved an empty data for store:', worksAt.store.id);
        return;
      }

      const id = docSnap.id;

      return { roles: worksAt.roles, store: mapToStore(id, data) };
    } catch (error) {
      console.error('Error fetching worksAt data:', worksAt.store, error);
      return;
    }
  });

  const results = await Promise.all(worksAtPromises);
  return results.filter((result): result is WorksAt => result !== undefined && Object.keys(result).length > 0);
};

export const getUser = async (id: string): Promise<User | undefined> => {
  try {
    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (!data) {
      console.warn('Retrieved empty data for user:', id);
      return;
    }

    const worksAtList = data.works_at || [];
    const processedWorksAtList = await fetchWorksAtData(worksAtList);

    return {
      id: id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      worksAt: processedWorksAtList,
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return;
  }
};

export const fetchUser = (
  id: string,
  onDataUpdate: (user: User | undefined) => void,
  onError: (error: Error) => void,
) => {
  const docRef = doc(db, 'users', id);

  return onSnapshot(
    docRef,
    async (docSnap) => {
      try {
        const data = docSnap.data();
        if (data) {
          const worksAtList = data.works_at || [];
          const processedWorksAtList = await fetchWorksAtData(worksAtList);

          onDataUpdate({
            id: id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            worksAt: processedWorksAtList,
          });
        } else {
          onDataUpdate(undefined);
        }
      } catch (error) {
        if (error instanceof Error) {
          onError(error);
        } else {
          console.error(error);
          onError(new AppException('Unknown error occurred'));
        }
      }
    },
    onError,
  );
};

export const updateUser = async (id: string, { firstName, lastName, email }: Partial<Omit<User, 'id' | 'worksAt'>>) => {
  const docRef = doc(db, 'users', id);

  const updatedUserData: {
    first_name?: string;
    last_name?: string;
    email?: string;
  } = {};
  if (firstName !== undefined) updatedUserData.first_name = firstName;
  if (lastName !== undefined) updatedUserData.last_name = lastName;
  if (email !== undefined) updatedUserData.email = email;

  await setDoc(docRef, updatedUserData, { merge: true });
};
