import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  open: boolean;
  message: string | undefined;
  isError: boolean;
  id: number;
};

export const StatusBar = (props: Props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const severity = useMemo(() => (props.isError ? 'error' : 'success'), [props.isError]);

  const handleSnackbarClose = useCallback(() => setSnackbarOpen(false), []);

  useEffect(() => setSnackbarOpen(props.open), [props.id, props.open]);

  return (
    <div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant='filled' severity={severity} onClose={handleSnackbarClose}>
          {props.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
