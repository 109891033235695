import strings from '@/styles/strings';

export function validateLoginForm({ email, password }: { email?: string; password?: string }) {
  const errors: { email?: string; password?: string } = {};

  if (!email?.trim()) {
    errors.email = strings.emailEmptyErrorMessage;
  } else if (!isValidEmail(email)) {
    errors.email = strings.emailInvalidErrorMessage;
  }

  if (!password?.trim()) {
    errors.password = strings.passwordEmptyErrorMessage;
  }

  return errors;
}

export function validateResetPasswordForm({ email }: { email?: string }) {
  const errors: { email?: string; password?: string } = {};

  if (!email?.trim()) {
    errors.email = strings.emailEmptyErrorMessage;
  } else if (!isValidEmail(email)) {
    errors.email = strings.emailInvalidErrorMessage;
  }

  return errors;
}

export const passwordErrorMap = {
  1: strings.passwordValidationMinCharactersMessage,
  2: strings.passwordValidationLowerCaseMessage,
  3: strings.passwordValidationUpperCaseMessage,
  4: strings.passwordValidationNumberMessage,
};

export function validatePassword(password: string) {
  const error: { empty?: string; minCharacters?: string; lowerCase?: string; upperCase?: string; number?: string } = {};

  if (!password.trim()) {
    error.empty = strings.passwordEmptyErrorMessage;
  }

  for (const [errorCode, errorMessage] of Object.entries(passwordErrorMap)) {
    switch (errorCode) {
      case '1':
        if (password.length < 8) {
          error.minCharacters = errorMessage;
        }
        break;
      case '2':
        if (!/[a-z]/.test(password)) {
          error.lowerCase = errorMessage;
        }
        break;
      case '3':
        if (!/[A-Z]/.test(password)) {
          error.upperCase = errorMessage;
        }
        break;
      case '4':
        if (!/[0-9]/.test(password)) {
          error.number = errorMessage;
        }
        break;

      default:
        break;
    }
  }

  return error;
}

export function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
