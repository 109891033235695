import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as ArrowIcon } from '@/assets/svg/chevron_down.svg';

type Props = {
  name: string;
  value: any;
  options: any[];
  onChange: (event: any) => void;
};

export const Dropdown = ({ name, value, options, onChange }: Props) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      setSelectedValue(value);
      onChange({ name: name, value: value });
    },
    [name, onChange],
  );

  return (
    <FormControl fullWidth>
      <Select name={name} value={selectedValue} onChange={handleChange} IconComponent={ArrowIcon}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
