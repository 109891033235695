import { alpha } from '@mui/material';

const GREY = {
  5: '#F4F4F4',
  50: '#F9FAFA',
  100: '#F4F4F5',
  200: '#E7E6E6',
  300: '#D4D4D8',
  400: '#A1A1AA',
  500: '#71717A',
  600: '#52525B',
  700: '#3F3F46',
  800: '#27272A',
  900: '#18181B',
};

const ORANGE = {
  5: '#FFFAF3',
  10: '#FFF4E7',
  20: '#FEEACF',
  30: '#FEDFB8',
  40: '#FED5A0',
  50: '#FDCA88',
  60: '#FDBF70',
  70: '#FDB558',
  80: '#FDAA41',
  90: '#FCA029',
  100: '#FC9511',
};

const GREEN = {
  5: '#F6FAF7',
  10: '#ECF4EE',
  20: '#D9E9DE',
  30: '#C6DFCD',
  40: '#C6DFCD',
  50: '#A0C9AC',
  60: '#8EBE9B',
  70: '#7BB38B',
  80: '#68A97A',
  90: '#429359',
  100: '#FC9511',
};
const PRIMARY_DARK = '#202E2A';
const PRIMARY = {
  light: GREEN[50],
  main: '#306A57',
  dark: PRIMARY_DARK,
  contrastText: '#fff',
};

const SECONDARY = {
  light: GREY[500],
  main: GREY[700],
  dark: GREY[900],
  contrastText: '#fff',
};

const INFO_BASE = '#757B9A';
const INFO = {
  light: alpha(INFO_BASE, 0.5),
  main: INFO_BASE,
  dark: alpha(INFO_BASE, 0.9),
  contrastText: '#fff',
};

const SUCCESS_BASE = '#6E905C';
const SUCCESS = {
  light: alpha(SUCCESS_BASE, 0.5),
  main: SUCCESS_BASE,
  dark: alpha(SUCCESS_BASE, 0.9),
  contrastText: '#fff',
};

const WARNING_BASE = '#FAC850';
const WARNING = {
  light: alpha(WARNING_BASE, 0.5),
  main: WARNING_BASE,
  dark: alpha(WARNING_BASE, 0.9),
  contrastText: '#fff',
};
const ERROR_BASE = '#E65050';
const ERROR = {
  light: alpha(ERROR_BASE, 0.5),
  main: ERROR_BASE,
  dark: alpha(ERROR_BASE, 0.9),
  contrastText: '#fff',
};

const ACCENT_ORANGE = '#FB9410';

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: GREY[200],
  text: {
    primary: PRIMARY_DARK,
    secondary: GREY[800],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: GREY[200],
    selected: GREY[200],
    focus: GREY[200],
    hoverOpacity: 0.2,
  },
  orange: ORANGE,
  green: GREEN,
  accent: {
    orange: ACCENT_ORANGE,
  },
};

export default palette;

declare module '@mui/material/styles' {
  interface Palette {
    green: typeof GREEN;
    accent: {
      orange: string;
    };
  }
  interface PaletteOptions {
    green?: typeof GREEN;
    accent?: {
      orange: string;
    };
  }
}
