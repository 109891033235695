import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';

import { TextFormField } from '@/components';
import strings from '@/styles/strings';
import ChangeEmailDialog from './ChangeEmailDialog';

type Props = {
  name: string;
  value?: string;
  onSubmit: (data: { name: string; value?: string }) => void;
};

const EmailField = ({ name, value, onSubmit }: Props) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(value);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    setOpen(true);
  }, []);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleUpdateEmail = useCallback(() => {
    onSubmit({ name, value: email });
    setOpen(false);
  }, [email, name, onSubmit]);

  return (
    <Grid container direction='column'>
      <TextFormField
        title={strings.emailCaption}
        value={value}
        name={name}
        onSubmit={handleSubmit}
        onChange={onChange}
      />
      <ChangeEmailDialog open={open} onSubmit={handleUpdateEmail} onClose={handleClose} />
    </Grid>
  );
};

export default EmailField;
