import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar } from './components';
import HomePage from './features/Home/HomePage';
import LoginPage from './features/Login/LoginPage';
import withStatus from './hoc/withStatus';
import useLogin from './hooks/useLogin';
import type { AppState } from './store';
import ThemeProvider from './theme';

function App({ showStatusMessage }: { showStatusMessage: any }) {
  const uid = useSelector<AppState, string | undefined>((state) => state.user.uid);
  const { pending, handleAuthStateChanged } = useLogin();

  useEffect(() => handleAuthStateChanged(), [handleAuthStateChanged]);

  if (pending) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <ProgressBar />
      </Box>
    );
  }

  return (
    <ThemeProvider>
      {!uid ? <LoginPage showStatusMessage={showStatusMessage} /> : <HomePage showStatusMessage={showStatusMessage} />}
    </ThemeProvider>
  );
}

export default withStatus(App);
