import { StatusBar } from '@/components';
import React, { useCallback, useRef, useState } from 'react';

export type StatusMessageCallback = (message: string, isError?: boolean) => void;

function withStatus(Component: React.ComponentType<any>) {
  return function WithGlobalStatus(props: React.JSX.IntrinsicAttributes) {
    const id = useRef(0);
    const [status, setStatus] = useState<{
      message: string | undefined;
      isError: boolean;
    }>({ message: undefined, isError: false });

    const showStatusMessage: StatusMessageCallback = useCallback((message, isError = false) => {
      id.current += 1;
      setStatus({ message, isError });
    }, []);

    return (
      <div>
        <StatusBar id={id.current} message={status.message} isError={status.isError} open={status.message != null} />
        <Component {...props} showStatusMessage={showStatusMessage} />
      </div>
    );
  };
}

export default withStatus;
