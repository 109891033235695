import Button from '@mui/material/Button';
import { useState } from 'react';

import strings from '@/styles/strings';
import ChangePasswordDialog from './ChangePasswordDialog';

type Props = {
  onSubmit: (data: { name: 'password'; value: string }) => Promise<void>;
};

const ChangePasswordButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (value: string) => {
    await props.onSubmit({ name: 'password', value: value });
    setOpen(false);
  };

  return (
    <div>
      <Button type='submit' onClick={handleClickOpen} variant='text'>
        {strings.changePasswordCaption}
      </Button>
      <ChangePasswordDialog open={open} onSubmit={handleSubmit} onClose={handleClose} />
    </div>
  );
};

export default ChangePasswordButton;
