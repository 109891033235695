import html2canvas from 'html2canvas';
import { useState } from 'react';

import { SendEmailData, sendEmail } from '@/api/contact';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone.svg';
import { NavButton } from '@/components';
import type { Store, User } from '@/data';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import strings from '@/styles/strings';
import ContactFormDialog from './ContactDialog';

type Props = {
  store: Store;
  showStatusMessage: StatusMessageCallback;
  expand: boolean;
  user: User;
};

const ContactButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function captureScreenshotAndCreateFile(): Promise<File | undefined> {
    const excludedElement = document.getElementById('dialog');
    excludedElement?.setAttribute('data-html2canvas-ignore', 'true');

    const canvas = await html2canvas(document.body);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const screenshotFile = new File([blob], 'screenshot.png', { type: 'image/png' });
          resolve(screenshotFile);
        } else {
          resolve(undefined);
        }
      }, 'image/png');
    });
  }

  const handleSubmit = async (data: SendEmailData) => {
    try {
      if (data.screenshot) {
        data.attachment = await captureScreenshotAndCreateFile();
      }
      const response = await sendEmail(props.store, data);
      if (response) {
        props.showStatusMessage(strings.contactUsSuccessMessage);

        setOpen(false);
      }
    } catch (error) {
      props.showStatusMessage(strings.genericErrorMessage, true);
    }
  };

  return (
    <div>
      <NavButton
        open={open}
        expand={props.expand}
        text={strings.contactCaption}
        icon={<PhoneIcon />}
        onClick={handleClickOpen}
      />
      <ContactFormDialog
        user={props.user}
        contact={props.store.personOfContact}
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default ContactButton;
