import { analytics } from '@/services';
import { logEvent } from 'firebase/analytics';

const addWebPrefix = (eventName: string) => `web_${eventName}`;

export const logLoginEvent = (email: string, userId: string) => {
  logEvent(analytics, addWebPrefix('login'), {
    email: email,
    user_id: userId,
  });
};

export const logLogoutEvent = () => {
  logEvent(analytics, addWebPrefix('logout'));
};

export const logForgotPasswordEvent = (email: any) => {
  logEvent(analytics, addWebPrefix('forgot_password'), {
    email: email,
  });
};

export const logIncorrectPasswordEvent = (email: string) => {
  logEvent(analytics, addWebPrefix('incorrect_password'), {
    email: email,
  });
};

export const logUserNotFoundEvent = (email: string) => {
  console.log(analytics);
  logEvent(analytics, addWebPrefix('user_not_found'), {
    email: email,
  });
};

export const logSelectStoreEvent = (storeId: any, storeName: any) => {
  logEvent(analytics, addWebPrefix('select_store'), {
    store_id: storeId,
    store_name: storeName,
  });
};
