import { CircularProgress } from '@mui/material';
import React from 'react';

export const ProgressBar = (props: { color?: React.CSSProperties['color'] }) => {
  return (
    <CircularProgress
      size={24}
      sx={{
        position: 'center',
        color: props.color || 'primary',
      }}
    />
  );
};
