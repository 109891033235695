import { Box, Grid, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import React, { useCallback, useEffect, useState } from 'react';

type Color = React.CSSProperties['color'];

type ColorsProp = {
  color?: Color;
  backgroundColor?: Color;
};

const StyledGrid = styled(Grid)(({ color, backgroundColor }: ColorsProp) => ({
  padding: '8px 4px',
  width: '180px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: color,
  backgroundColor: backgroundColor,
  height: '48px',
  transition: 'transform 0.3s ease',
}));

const StyledIconButton = styled(IconButton)(({ color, backgroundColor }: ColorsProp) => ({
  borderRadius: '8px',
  padding: '16px 14px',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  color: color,
  backgroundColor: backgroundColor,
}));

const StyleIcon = styled(IconButton)(({ color }) => ({
  justifyContent: 'center',
  textAlign: 'center',
  color: color,
}));

type Props = {
  open: boolean;
  expand: boolean;
  icon: JSX.Element;
  text: string;
  onClick: () => void;
};

export const NavButton = ({ open: propOpen, expand: propExpand, icon: propIcon, text, onClick }: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(propOpen);
  const [expand, setExpand] = useState(propExpand ?? true);

  const color = open ? theme.palette.accent.orange : theme.palette.grey[300];

  const icon = React.cloneElement(propIcon, { fill: color });

  useEffect(() => {
    setOpen(propOpen);
  }, [propOpen]);

  useEffect(() => {
    setExpand(propExpand ?? true);
  }, [propExpand]);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<unknown, unknown>) => {
      event.preventDefault();

      if (open) {
        return;
      }

      setOpen(true);
      onClick();
    },
    [open, onClick],
  );

  if (!expand) {
    return (
      <Tooltip title={text}>
        <StyledIconButton onClick={handleOnClick}>{icon}</StyledIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledGrid container direction='row' alignItems='center' onClick={handleOnClick} color={color}>
      <Grid item>
        <StyleIcon disabled={true}> {icon}</StyleIcon>
      </Grid>
      <Grid item pl='6px'>
        <Box width='120px'>
          <Typography variant='h5' fontWeight='500' color={color}>
            {text}
          </Typography>
        </Box>
      </Grid>
    </StyledGrid>
  );
};
