import { AppBar, Box, Divider, Toolbar, styled } from '@mui/material';

import { ReactComponent as Logo } from '@/assets/svg/logo_white.svg';
import type { Store, User } from '@/data';
import UserInfo from '@/features/Home/UserInfo';
import { StoreSelector } from './StoreSelector';

const TopNavBar = styled(AppBar)(() => ({
  height: '48px',
  justifyContent: 'center',
  paddingLeft: '18px',
  paddingRight: '46px',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '0.5px',
  background: theme.palette.common.white,
  height: '24px',
  opacity: 0.5,
}));

const StyledBox = styled(Box)(() => ({
  flexGrow: 1,
}));

export const TopBar = (props: { store: Store; user: User }) => {
  return (
    <TopNavBar position='static' elevation={0}>
      <Toolbar disableGutters>
        <StyledBox pt='6px'>
          <Logo />
        </StyledBox>
        <Box>
          <StoreSelector selectedStore={props.store} user={props.user} />
        </Box>
        <Box m='31px'>
          <StyledDivider orientation='vertical' variant='middle' />
        </Box>
        <Box>
          <UserInfo user={props.user} store={props.store} />
        </Box>
      </Toolbar>
    </TopNavBar>
  );
};
