import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';

import type { StatusMessageCallback } from '@/hoc/withStatus';
import useLogin from '@/hooks/useLogin';
import strings from '@/styles/strings';
import { AppException } from '@/utils/app-exception';
import ResetPasswordDialog from './ResetPasswordDialog';

type Props = {
  email: string;
  showStatusMessage: StatusMessageCallback;
};

const ForgotPasswordButton = ({ email, showStatusMessage }: Props) => {
  const { resetPassword } = useLogin();
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(setOpen, [setOpen]);

  const handleReset = useCallback(
    async (value: string) => {
      try {
        await resetPassword(value);
        showStatusMessage(strings.resetPasswordSuccessMessage);
        handleToggle(false);
      } catch (error) {
        if (error instanceof AppException) {
          showStatusMessage(error.message, true);
        } else {
          console.error(error);
          showStatusMessage(strings.genericErrorMessage, true);
        }
      }
    },
    [showStatusMessage, resetPassword, handleToggle],
  );

  return (
    <div>
      <Button onClick={() => handleToggle(true)} variant='text'>
        {strings.forgotPasswordCaption}
      </Button>
      <ResetPasswordDialog open={open} onReset={handleReset} onClose={() => handleToggle(false)} email={email} />
    </div>
  );
};

export default ForgotPasswordButton;
