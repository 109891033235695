import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useReducer } from 'react';

import { loadDashboard } from '@/api/dashboard';
import { ProgressBar } from '@/components';
import type { Dashboard as DashboardModel, Store } from '@/data';
import useLogin from '@/hooks/useLogin';
import { getStoreDashboardUrl } from '@/services';
import strings from '@/styles/strings';
import { AppException } from '@/utils/app-exception';

const ErrorContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

const ErrorMessage = styled('div')(() => ({
  textAlign: 'center',
  fontSize: '1.2rem',
}));

const DashboardContainer = styled('div')(() => ({
  width: '100%',
  height: '100vh',
}));

enum ReducerActionType {
  LOADING,
  LOADED,
  ERROR,
}

type ReducerState = {
  src?: string;
  url?: string;
  error?: Error;
};

const loadingAction = () => ({ type: ReducerActionType.LOADING }) as const;
const errorAction = (error: Error) => ({ type: ReducerActionType.ERROR, error }) as const;
const loadedAction = (src: string, url: string) => ({ type: ReducerActionType.LOADED, src, url }) as const;

function reducer(
  _: ReducerState,
  action: ReturnType<typeof loadingAction> | ReturnType<typeof loadedAction> | ReturnType<typeof errorAction>,
): ReducerState {
  switch (action.type) {
    case ReducerActionType.LOADING:
      return {};
    case ReducerActionType.LOADED:
      return { src: action.src, url: action.url };
    case ReducerActionType.ERROR:
      return { error: action.error };
    default:
      throw new Error('DEV error: unknown action type in Dashboard reducer.');
  }
}

type Props = {
  data: DashboardModel;
  store: Store;
};

const Dashboard = (props: Props) => {
  const { getIdTokenForCurrentUser } = useLogin();

  const [{ error, src, url }, dispatch] = useReducer(reducer, {});

  const patchIframeWindowFetch: React.ReactEventHandler<HTMLIFrameElement> = useCallback(
    ({ target }) => {
      function isIframe(): HTMLIFrameElement | undefined {
        return 'contentWindow' in target ? (target as HTMLIFrameElement) : undefined;
      }

      const iframe = isIframe();
      if (iframe == null || !iframe.contentWindow || !url) {
        return;
      }

      const { pathname } = new URL(url);

      const previousFetch = iframe.contentWindow.fetch;
      iframe.contentWindow.fetch = async function (url, options) {
        type JsonData = { [key: string]: any };
        let requestBody = null;
        if (url.toString().endsWith('_dash-update-component') && options != null && options.body != null) {
          requestBody = JSON.parse(options.body as string) as JsonData;
          if ('inputs' in requestBody) {
            for (let i = 0; i < requestBody['inputs'].length; i++) {
              let input = requestBody['inputs'][i];
              if (
                'property' in input &&
                input['property'] === 'pathname' &&
                'value' in input &&
                input['value'].toLowerCase() === 'srcdoc'
              ) {
                requestBody['inputs'][i]['value'] = pathname;
              }
            }
          }
        }

        return await previousFetch(url, {
          ...options,
          body: requestBody === null ? options?.body : JSON.stringify(requestBody),
        });
      };
    },
    [url],
  );

  useEffect(() => {
    (async function () {
      dispatch(loadingAction());

      if (!props.data?.id) {
        dispatch(errorAction(new AppException(strings.dashboardNotFoundMessage)));
        return;
      }

      try {
        const url = await getStoreDashboardUrl(props.store.id, props.data.id);
        if (!url) {
          dispatch(errorAction(new AppException(strings.dashboardNotFoundMessage)));
          return;
        }

        const token = await getIdTokenForCurrentUser();
        const src = await loadDashboard(url, token);
        dispatch(loadedAction(src, url));
      } catch (error) {
        if (error instanceof AppException) {
          dispatch(errorAction(error));
        } else {
          console.error('Error fetching dashboard:', error);
          dispatch(errorAction(new AppException(strings.genericErrorMessage)));
        }
      }
    })();
  }, [getIdTokenForCurrentUser, props.data.id, props.store.id]);

  return (
    <Paper>
      <DashboardContainer>
        {src && (
          <iframe
            srcDoc={src}
            onLoad={patchIframeWindowFetch}
            title='dashboard'
            style={{ border: 'none', height: '100%', width: '100%', paddingBottom: '3em' }}
          />
        )}
        {!src && !error && (
          <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
            <ProgressBar />
          </Box>
        )}
        {error && (
          <ErrorContainer>
            <ErrorMessage>
              <Typography variant='body2'>{error.message}</Typography>
            </ErrorMessage>
          </ErrorContainer>
        )}
      </DashboardContainer>
    </Paper>
  );
};

export default Dashboard;
