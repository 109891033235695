import { Grid, Typography } from '@mui/material';
import { Fragment } from 'react';

import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import strings from '@/styles/strings';

const Hero = () => {
  const formattedTagLine = strings.tagLine.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
  return (
    <Grid container direction='column' pt='58px' pl='141px' pr='141px'>
      <Grid item>
        <Logo />
      </Grid>
      <Grid item pt='92px'>
        <Typography variant='h1' color='primary'>
          {formattedTagLine}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Hero;
