import { Collapse, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';

import { ReactComponent as SettingsIcon } from '@/assets/svg/settings.svg';
import { NavButton, ToggleHeader } from '@/components';
import type { Dashboard, Store, User } from '@/data';
import ContactButton from '@/features/Contact/ContactButton';
import DashboardList from '@/features/Dashboard/DashboardList';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import strings from '@/styles/strings';
import Logout from './Logout';

const RootGrid = styled(Grid)(({ theme }) => ({
  height: 'calc(100vh - 48px)',
  backgroundColor: theme.palette.primary.dark,
  boxShadow: '0px 0px 8px 0px rgba(39, 39, 42, 0.04)',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  border: 'none',
  height: 1,
  margin: 0,
  backgroundColor: theme.palette.grey[400],
}));

type Props = {
  store: Store;
  showDashboard: (dashboard: Dashboard) => void;
  updateDashboard: (dashboard: Dashboard | undefined) => void;
  showSettings: boolean;
  user: User;
  showStatusMessage: StatusMessageCallback;
  openSettingsMenu: () => void;
};

const SideBar = (props: Props) => {
  const [expand, setExpand] = useState(true);

  const handleOnToggle = useCallback((value: boolean) => {
    setExpand(value);
  }, []);

  return (
    <Collapse in={expand} collapsedSize={80} orientation='horizontal'>
      <RootGrid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        pb='40px'
        pl='8px'
        pr='16px'
      >
        <Grid container item direction='column'>
          <Grid container item pt='24px' justifyContent='center'>
            <ToggleHeader text={strings.dashboardCaption} onToggle={handleOnToggle} />
          </Grid>
          <Grid item alignItems='start'>
            <DashboardList
              storeId={props.store.id}
              onSelect={props.showDashboard}
              onUpdate={props.updateDashboard}
              active={!props.showSettings}
              expand={expand}
              showStatusMessage={props.showStatusMessage}
            />
          </Grid>
        </Grid>
        <Grid container item direction='column'>
          <StyledDivider />
          <Grid item pt='8px' alignItems='center'>
            <ContactButton
              user={props.user}
              store={props.store}
              expand={expand}
              showStatusMessage={props.showStatusMessage}
            />
          </Grid>
          <Grid container item pt='8px' justifyContent='center'>
            <NavButton
              open={props.showSettings}
              expand={expand}
              text={strings.settingCaption}
              icon={<SettingsIcon />}
              onClick={props.openSettingsMenu}
            />
          </Grid>
          <Grid container item pt='8px' justifyContent='center'>
            <Logout expand={expand} showStatusMessage={props.showStatusMessage} />
          </Grid>
        </Grid>
      </RootGrid>
    </Collapse>
  );
};

export default SideBar;
