import { Theme } from '@mui/material';

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': theme.palette.grey[200],
          '--TextField-brandBorderHoverColor': theme.palette.grey[400],
        },
      },
    },
  };
}
