import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import Banner from '@/assets/svg/banner.svg';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import Hero from './Hero';
import Login from './Login';

const Root = styled('div')(() => ({
  backgroundImage: `url(${Banner})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
  display: 'flex',
}));

type Props = {
  showStatusMessage: StatusMessageCallback;
};

const LoginPage = (props: Props) => {
  return (
    <Root>
      <Grid container direction='row' alignItems='stretch' justifyContent='space-between'>
        <Grid container item md={6} sm={12}>
          <Hero />
        </Grid>
        <Grid container item md={6} sm={12}>
          <Login showStatusMessage={props.showStatusMessage} />
        </Grid>
      </Grid>
    </Root>
  );
};

export default LoginPage;
