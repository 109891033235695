import { SxProps, TextField, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { ReactComponent as VisibilityOffIcon } from '@/assets/svg/visibility_off.svg';
import { ReactComponent as VisibilityOnIcon } from '@/assets/svg/visibility_on.svg';
import React, { useCallback, useState } from 'react';

type Props = {
  name: string;
  value: string;
  style?: SxProps<Theme>;
  error: boolean;
  errorText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

export const PasswordField = ({ name, value, style, error, errorText, onChange, label }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    [],
  );

  return (
    <TextField
      label={label}
      name={name}
      value={value}
      error={error}
      onChange={onChange}
      sx={style}
      size='small'
      helperText={errorText}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
