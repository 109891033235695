import useLogin from '@/hooks/useLogin';
import strings from '@/styles/strings';

import { ReactComponent as LogoutIcon } from '@/assets/svg/logout.svg';
import { NavButton } from '@/components';
import type { StatusMessageCallback } from '@/hoc/withStatus';
import { useCallback } from 'react';

type Props = {
  expand: boolean;
  showStatusMessage: StatusMessageCallback;
};

const Logout = ({ expand, showStatusMessage }: Props) => {
  const { logout } = useLogin();

  const handleSubmit = useCallback(() => {
    try {
      logout();
    } catch (error) {
      if (error instanceof Error) {
        showStatusMessage(error.message, true);
      } else {
        console.error('Error logging out:', error);
        showStatusMessage(strings.genericErrorMessage, true);
      }
    }
  }, [logout, showStatusMessage]);

  return (
    <NavButton open={false} expand={expand} text={strings.logoutCaption} icon={<LogoutIcon />} onClick={handleSubmit} />
  );
};

export default Logout;
